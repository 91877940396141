import React, { useEffect, useState } from "react";
// motion
import { motion } from "framer-motion";
// variants
import { fadeIn } from "../variants";
import axios from "axios";

const Contact = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [text, setText] = useState("");
  const [error, setError] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!text || !email || !text) {
      setError("fill the form properly");
    } else {
      setError("");
      axios
        .post("https://back-end-portfolio.onrender.com", {
          firstName: name,
          text: text,
          email: email,
        })
        .then((res) => {
          alert(res.data);
          setEmail("");
          setName("");
          setText("");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <section className="py-16 lg:section" id="contact">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row">
          {/* text */}
          <motion.div
            variants={fadeIn("right", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1 flex justify-start items-center"
          >
            <div>
              <h4 className="text-xl uppercase text-accent font-medium mb-2 tracking-wide">
                Get in touch
              </h4>
              <h2 className="text-[45px] lg:text-[90px] leading-none mb-12">
                Let's work <br /> together!
              </h2>
            </div>
          </motion.div>
          {/* form */}
          <motion.form
            variants={fadeIn("left", 0.5)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1 border rounded-2xl p-6 pb-16 lg:pb-20 gap-y-6 flex flex-col items-start"
          >
            <input
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              className="bg-transparent border-b py-3 outline-none w-full placeholder:text-white focus:border-accent transition-all"
              type="text"
              placeholder="Your name"
            />
            <input
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              value={email}
              className="bg-transparent border-b py-3 outline-none w-full placeholder:text-white focus:border-accent transition-all"
              type="email"
              placeholder="Your email"
            />
            <textarea
              onChange={(e) => {
                setText(e.target.value);
              }}
              value={text}
              className="lg:mb-10 mb-8 bg-transparent border-b py-3 outline-none w-full placeholder:text-white focus:border-accent transition-all resize-none"
              type="text"
              name=""
              id=""
              cols={30}
              rows={6}
              placeholder="Your Message"
            ></textarea>
            {error && <p>{error}</p>}
            <button onClick={handleSubmit} type="submit" className="btn btn-lg">
              Send Message
            </button>
          </motion.form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
