import React from "react";
// motion
import { motion } from "framer-motion";
// variants
import { fadeIn } from "../variants";
// import images
import Img1 from "../assets/Amazon-image.png";
import Img2 from "../assets/Apple-image.png";
import Img3 from "../assets/Netflix-image.png";
import { Link } from "react-router-dom";

const Work = () => {
  return (
    <section className="section" id="work">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row gap-x-10 lg:mt-16">
          <motion.div
            variants={fadeIn("right", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1 flex flex-col gap-y-12 mb-10 lg:mb-0"
          >
            {/* text */}
            <div className="">
              <h2 className="h2 leading-tight text-accent">
                My Latest <br />
                projects.
              </h2>
              <p className="max-w-sm mb-10">
                {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint,
                veniam enim ad repudiandae odio recusandae accusantium totam
                dolorem harum eum. */}
              </p>
              <button className="btn btn-sm">View all projects</button>
            </div>

            {/* image */}
            <Link to="https://alula-react-amazon.netlify.app/">
              <div className="group relative overflow-hidden border-2 border-white/50 rounded-xl ">
                {/* overlays */}
                <div className="group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300"></div>
                {/* img */}
                <img
                  src="https://images.unsplash.com/photo-1633174524827-db00a6b7bc74?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1796&q=80"
                  alt=""
                  className="group-hover:scale-125 transition-all duration-500 z-50"
                />
                {/* subtitle */}
                <div className="absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50">
                  <span className="text-gradient">Web Application</span>
                </div>
                {/* title */}
                <div className="absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50">
                  <span className="text-3xl text-white">Amazon website</span>
                </div>
              </div>
            </Link>
          </motion.div>
          <motion.div
            variants={fadeIn("left", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1 flex flex-col gap-y-10 lg:gap-y-12"
          >
            {/* image */}
            <Link to="https://apple-website-five.vercel.app/">
              <div className="group relative overflow-hidden border-2 border-white/50 rounded-xl ">
                {/* overlays */}
                <div className="group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300"></div>
                {/* img */}
                <img
                  src={Img2}
                  alt=""
                  className="group-hover:scale-125 transition-all duration-500 z-50"
                />
                {/* subtitle */}
                <div className="absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50">
                  <span className="text-gradient">Web Application</span>
                </div>
                {/* title */}
                <div className="absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50">
                  <span className="text-3xl text-white">Apple Website</span>
                </div>
              </div>
            </Link>
            {/* image */}
            <Link to="https://netflix-website-wheat.vercel.app/">
              <div className="group relative overflow-hidden border-2 border-white/50 rounded-xl ">
                {/* overlays */}
                <div className="group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300"></div>
                {/* img */}
                <img
                  src="https://images.unsplash.com/photo-1621955964441-c173e01c135b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2086&q=80"
                  alt=""
                  className="group-hover:scale-125 transition-all duration-500 z-50"
                />
                {/* subtitle */}
                <div className="absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50">
                  <span className="text-gradient">Web application</span>
                </div>
                {/* title */}
                <div className="absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50">
                  <span className="text-3xl text-white">Netflix Website</span>
                </div>
              </div>
            </Link>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Work;
